<script setup lang="ts">
	import { useLocationsStore } from '~/stores/LocationsStore';
	import { useCarsStore } from '~/stores/CarsStore';
	const nuxtApp = useNuxtApp();
	const router = useRouter();
	const cars = useCarsStore();

	const locationsStore = useLocationsStore();
	let now = new Date();
	now.setDate(now.getDate() +  3);
	let dateStart = ref(now);
	let now2 = new Date();
	now2.setDate(now2.getDate() +  10);
	let dateEnd = ref(now2);

	const setLocation = async (location: Object) => {
		let to = '';
		let date_1 = nuxtApp.$moment(dateStart.value).format('DD.MM.YYYY');
		let date_2 = nuxtApp.$moment(dateEnd.value).format('DD.MM.YYYY');
		let promo = '';
		router.push(`/${location.slug}?from-id=${location.id}&to-id=${to}&from-date=${date_1}&to-date=${date_2}&from-time=12:00&to-time=12:00&promocode=${promo}`);
		cars.setClassId(0)
	};

</script>

<template>
	<div>
		<h1 class="cards__title">Арендa авто по всeй России и за рубежoм</h1>
		<div class="section cards">
			<div class="card" v-for="location in locationsStore.list.filter(l => l.published)">
				<div class="card__image">
					<a href="javascript:void(0)" @click="setLocation(location)"><img :src="`https://mycarrental.ru/uploads/${location.page_image}`" alt=""></a>
				</div>
				<div class="card__title">{{location.caption}}</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
	.cards__title {
		font-size: 24px;
		line-height: 30px;
		opacity: 0.8;
		text-align: center;
		padding-top: 40px;
	}
</style>